<template lang="pug">
  div
    slot(:hcb="hcb")

</template>

<script>
  import vueColorbarSvc from "@services/vueColorbarSvc";

  export default {
    name: "ClosestHairColorBar",

    data: () => ({
      hcb: null
    }),

    inject: ['getObjProperty'],

    mounted() {
      vueColorbarSvc.getClosestLocationsByIp().then(res => {
        let hcb = this.getObjProperty(res, 'data.0');
        if (hcb) {
          this.hcb = hcb;
        }
      });
    }
  };
</script>

<style scoped lang="stylus">
</style>
